<template>
    <div class="container">
        <div class="row">
            <div class="header">
                <h1 class="site-title display-3">Yukiko <small>is Shutting down.</small></h1>
                <h3 class="headline">We have reached the neutral ending!</h3>
            </div>
            <div class="image">
                <img src="https://cdn.yukiko.app/web/HomeYukiko.webp" height="500" class="mt-4 rounded contain">
            </div>
            <div class="shutdown">
                <h3>Message to all our users.</h3>
                <p>
                    We regret to inform you that due to the closure of Stellar Corporation, the Yukiko App will cease
                    all operations on September 1st, 2024. As a result, the app will no longer respond to commands,
                    track XP, or perform any other functions.
                </p>
                <p>
                    While we are sad to see this chapter end, we remain hopeful for a future comeback. Yukiko will
                    remain "idle" on Discord, but it will not be active or responsive.
                </p>
                <p>
                    Yukiko has been a labor of love for our dedicated team. We take great pride in the unique features
                    and capabilities we were able to deliver. We are deeply grateful to our amazing users who
                    contributed valuable feedback and support throughout Yukiko's journey.
                </p>
                <p>
                    We deeply appreciate your support and the vibrant community that has grown around Yukiko. Thank you
                    for being with us on this journey. We hope to reconnect with you in the future.
                </p>
                <p>
                    We apologize for any inconvenience this may cause and want to thank you for being part of our
                    journey. We remain hopeful for a future return and hope that our paths will cross again.
                </p>
                <br>

                <div class="text-spacer"></div> <br>
                <h3>Q&A</h3>
                <div class="qna">
                    <ul>
                        <li>
                            Q: Why are you shutting down Yukiko?
                            <br>
                            A: To be brutally honest, The holding company that owned Stellar Corporation and Yukiko
                            collapsed due to financial issues, taking with it Stellar, and Yukiko. <br />
                        </li>
                        <li>
                            Q: What will happen to the Yukiko website?
                            <br>
                            A: The website will be kept online with this page.
                        </li>
                        <li>
                            Q: What will happen to the Yukiko's support server?
                            <br>
                            A: We will archive it, frozen in time. No invite will be available, nor the possibility to
                            send messages.
                        </li>
                        <li>
                            Q: When will the Yukiko bot be shut down? <br>
                            A: Starting on September 1st, 2024, Yukiko will transition to an "Away" status, and you'll
                            no longer be able to add it to any new guilds. In addition, the dashboard will be closed,
                            and users won't be able to access it anymore.
                        </li>
                        <li>
                            Q: Will there be any alternative bots available for me to use? <br>
                            A: we have a few recommendations for you to check out, such as: <a
                                href="https://dyno.gg/">Dyno</a>, <a href="https://maki.gg/">Maki</a>, <a
                                href="https://carl.gg/">Tatsu</a>, <a href="https://carl.gg/">Carl-bot</a>
                        </li>
                        <li>
                            Q: Why wasn't more notice given before the shutdown? <br>
                            A: We were focused on trying to save the company, let's say we fought until the end.
                        </li>
                        <li>
                            Q: Can I still contact the Yukiko team after the shutdown? <br>
                            A: Yes, you can always contact us at Contact@Yukiko.app if you have any questions or
                            concerns.
                        </li>
                        <li>
                            Q: Will the Yukiko bot be open-sourced? <br>
                            A: No, the bot will not be open-sourced.
                        </li>
                        <li>
                            Q: Will you actually comeback? <br>
                            A: We can't say for sure, but we hope to rebuild either Asthriona ltd. and/or Stellar
                            Corporation.
                        </li>
                    </ul>
                </div>
                <div class="text-spacer"></div> <br>
                <!-- <h3>Message from the founders.</h3>
                <div class="owner-text">
                    <div class="owner-name">
                        <img src="https://cdn.asthriona.com/i/2023/05/99f234627faa917ace3998fa29755d5b"
                            alt="Asthriona's avatar" class="owner-avatar">
                        <i class="owner-name-text">Makoto <b>"Asthriona"</b> Kobayashi</i> <br>
                    </div>
                    <p>
                        Yukiko was my passion project. I started it back in 2019 with the goal of learning my way into
                        code. I had no
                        idea what I was doing, but I was determined to make something. It all began with a simple bot
                        that could only
                        do a few things, but I kept adding more and more features, and eventually, Yukiko became a bot
                        that could do
                        almost anything. I learned a lot from this project, and looking back, I'm very proud of what
                        I've
                        accomplished. The journey wasn't always smooth, we had our ups and downs. I'll never forget the
                        excitement of
                        the day Yukiko got verified by Discord, and how we decided to celebrate with a beer. I'll also
                        never forget
                        the frustration of the first ToS violation, but we persevered and continued to improve the bot.
                        Now, after
                        almost four years of working on Yukiko, it's time to move on. I'm not sure what the future
                        holds, but I'm
                        excited to find out.
                    </p>
                </div>
                <div class="owner-text">
                    <div class="owner-name">
                        <img src="https://kyoko-cdn.s3.ap-northeast-1.amazonaws.com/61705606.png" alt="kyoko's avatar"
                            class="owner-avatar">
                        <i class="owner-name-text">Kyoko <b>"Rise"</b> Takahashi</i>
                    </div>
                    <p>
                        I joined the Yukiko Dev Team (now known as Stellar) in 2020, and I've been working on it ever
                        since. Even as a
                        full-time front-end developer, I had never built a bot before, but I learned a lot from this
                        project. Working
                        on Yukiko took me to places I never went before, and I'm proud of what we accomplished. We made
                        many
                        unforgettable memories, even during the toughest times of the project. Sure, it could be
                        stressful at times,
                        but looking back, it helped us all to grow and make something great. I don't regret following
                        that crazy
                        guy on their adventure, or the sleepless nights being on call. It's sad to see the project
                        come to an
                        end, but I'm sure we'll be back at some points!
                    </p>
                </div> -->
                <!-- owner info cards -->
                <div class="card-container">
                    <!-- Mako's card -->
                    <div class="card">
                        <div class="card-img">
                            <img src="https://cdn.asthriona.com/i/2024/08/04853fceaae02025080e4b40392ff247.png"
                                alt="Asthriona's avatar">
                        </div>
                        <div class="card-title">
                            <h3>Makoto <b>"Asthriona"</b> Kobayashi</h3>
                        </div>
                        <div class="card-text">
                            <p>
                                Yukiko and Stellar Corporation Founder. <br>
                                Bot developer.
                            </p>
                        </div>
                        <div class="card-links">
                            <a href="https://asthriona.com" target="_blank" rel="noopener noreferrer">
                                Website
                            </a> |
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <i class="mdi mdi-discord">Discord: asthriona</i>
                            </a> |
                            <a href="https://Twitter.com/Asthriona" target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>
                        </div>
                    </div>
                    <!-- Kyoko's card -->
                    <div class="card">
                        <div class="card-img">
                            <img src="https://kyoko-cdn.s3.ap-northeast-1.amazonaws.com/61705606.png"
                                alt="kyoko's avatar">
                        </div>
                        <div class="card-title">
                            <h3>Kyoko <b>"Heazher"</b> Takahashi</h3>
                        </div>
                        <div class="card-text">
                            <p>
                                Yukiko and Stellar Corporation Co-Founder. <br>
                                Web developement.
                            </p>
                        </div>
                        <div class="card-links">
                            <a href="https://kyokodev.xyz" target="_blank" rel="noopener noreferrer">
                                Website
                            </a> |
                            <a href="" target="_blank" rel="noopener noreferrer">
                                <i class="mdi mdi-discord">Discord: kyoko_dev</i>
                            </a> |
                            <a href="https://Twitter.com/KyokoDev" target="_blank" rel="noopener noreferrer">
                                Twitter
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
document.title = 'Yukiko - Discordボット"を言います。';
</script>

<style scoped>
.container {
    margin: 0 auto;
    max-width: 1200px;
    text-align: center;
}

.row {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.header {
    margin-top: 2rem;
}

.site-title {
    font-size: 4rem;
    font-weight: 700;
}

.headline {
    font-size: 2rem;
    font-weight: 400;
}

.image {
    margin-top: 2rem;
}

.shutdown {
    margin-top: 2rem;
}

.shutdown .text-spacer {
    height: 1rem;
}

.shutdown p {
    margin-top: 1rem;
    text-align: justify;
}

.owner-text {
    text-align: left;
    margin-top: 2rem;
}

.owner-avatar {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin-right: 1rem;
    margin-top: .5rem;
}

.owner-name {
    display: flex;
    align-items: center;
}

.owner-name-text {
    font-size: 1.5rem;
    font-weight: 200;
}

.owner-name-text b {
    font-weight: 500;
}

.qna {
    margin-top: 2rem;
}

.qna .text-spacer {
    height: 1rem;
}

.qna li {
    margin-top: 1rem;
    text-align: justify;
    list-style-type: none;
}

/* Card must be next to eachother */
.card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    justify-items: center;
    grid-gap: 1rem;
    margin-top: 2rem;
}

.card {
    width: 25rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, .2);
    transition: all .2s ease-in-out;
    background-color: #1b1c1f
}

.card .card-img {
    width: 100%;
    height: 10rem;
    border-radius: 1rem 1rem 0 0;
    overflow: hidden;
}

.card .card-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card .card-title {
    margin-top: 1rem;
}

.card .card-title h3 {
    font-size: 1.5rem;
    font-weight: 500;
}

.card .card-text {
    margin-top: 1rem;
    text-align: justify;
    padding: 0 1rem;
}

.card .card-links {
    margin-top: 1rem;
    text-align: left;
    padding: 0 1rem;
}

.card .card-links a {
    color: #fff;
    text-decoration: none;
    font-size: 1.2rem;
    font-weight: 500;
}

.card .card-links a:hover {
    text-decoration: underline;
    background-color: rgba(255, 255, 255, 0);
}
</style>