<template>
  <div id="app">
    <div v-if="anounce" class="anounce text-center">
      <!-- <h1>📢 {{ anounce }}</h1> -->
      <h3>📢 Yukiko is shutting down. <router-link to="/news/End-of-Service-Notice-for-Yukiko">Click here for more
          information</router-link></h3>
      <!-- <span class="text-right" @click="closeAnnounce()">❌</span> -->
    </div>
    <div class="maintenance" v-if="maintenance == true">
      <b-container>
        <b-row>
          <b-col cols="12" class="text-center">
            <b-img src="https://cdn.yukiko.app/web/HomeYukiko.webp" class="rouded mt-4" height="500"></b-img>
            <h1 class="text-center">Yukiko is shutting down.</h1>
            <p>To all our users,</p>
            <p class="text-left">
              We regret to inform you that due to the closure of <a href="https://stellar-corp.net" target="_blank" rel="noopener noreferrer"><b>Stellar Corporation</b></a>, <b>Yukiko</b> will cease all
              operations on September 1st, 2024. <br /> As a result, the app will no longer respond to commands, track XP, or
              perform any other functions.
            </p>
            <p class="text-left">
              While we are sad to see this chapter end, we remain hopeful for a future comeback. <b>Yukiko</b> will remain
              "idle" on <a href="https://discord.com" target="_blank" rel="noopener noreferrer">Discord</a>, but it will not be active or responsive.
            </p>
            <p class="text-left">
              We deeply appreciate your support and the vibrant community that has grown around Yukiko. Thank you for
              being with us on this journey. We hope to reconnect with you in the future.
            </p>
            <p class="text-left">
              &mdash; The Yukiko Dev Team <br></p>
          </b-col>
          <b-col cols="6">
            <b-card
    tag="article"
    style="max-width: 20rem;"
    class="mb-2"
    dark
  >
  <b-avatar size="128" src="https://cdn.asthriona.com/i/2024/08/04853fceaae02025080e4b40392ff247.png"></b-avatar>
  <b-card-title>Makoto Asthriona Kobayashi</b-card-title>
    <b-card-text>
      Some quick example text to build on the card title and make up the bulk of the card's content.
    </b-card-text>

    <b-button href="#" variant="primary">Go somewhere</b-button>
  </b-card>
          </b-col>
        </b-row>
      </b-container>
      <!-- <b-container>
        <b-row>
          <b-col cols="12" class="text-center">
            <b-img
            src="https://cdn.yukiko.app/web/HomeYukiko.webp"
            class="rouded mt-4"
            height="500"
          ></b-img>
            <h1 class="text-center">Yukiko is currently fighting shadow in the TV world!</h1>
      <p class="text-center">
        This website is currently under maintenance. You can follow the
        progression of this maintenance on our Status Page. <br>
        This is a temporary page to avoid any issue between our website and the bots. <br>
        Once the incident is fixed or maintenance is done, your access to the service will be restored. <br>
        Please excuse us for the inconvenience. <br>
        &mdash; The Yukiko Dev Team <br>
      <a href="https://yukikoapp.statuspage.io" style="color:#d86100;" target="_blank" rel="noopener noreferrer">Status page</a> ● <a href="https://twitter.com/yukikoApp" style="color:#d86100;" target="_blank" rel="noopener noreferrer">Twitter</a> ● <a href="https://discord.gg/eG6jyTH" style="color:#d86100;" target="_blank" rel="noopener noreferrer">Discord</a>
      </p>
          </b-col>
        </b-row>
      </b-container> -->
    </div>
    <div class="Yukiko-Router" v-else>
      <div class="page-wrap">
        <TheHeader />
        <router-view />
      </div>
      <TheFooter />
    </div>
  </div>
</template>

<script>
import TheHeader from "./components/TheHeader";
import TheFooter from "./components/TheFooter";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      anounce: "",
      maintenance: false,
      announcedClosed: false
    };
  },
  components: {
    TheHeader,
    TheFooter,
  },
  beforeMount() {
    const announceStatus = localStorage.getItem("announcedClosed");
    if (!announceStatus) {
      localStorage.setItem("announcedClosed", false);
    }
    axios
      .get(`${process.env.VUE_APP_URI}/api/agi/user/profile`, {
        withCredentials: true,
      })
      .then((res) => {
        const data = res.data;
        if (data.isban == true) {
          this.$router.push("/banned");
        } else {
          return;
        }
      });
    axios.get(`${process.env.VUE_APP_URI}/api/agi/user/notice`).then((res) => {
      if (res.data.message == "Null") {
        return;
      } else {
        this.anounce = res.data.message;
      }
    });
  },
  mounted() {
    let statusScript = document.createElement("script");
    statusScript.setAttribute(
      "src",
      "https://gzzy9n9f7gm1.statuspage.io/embed/script.js"
    );
    document.head.appendChild(statusScript);
  },
  methods: {
    closeAnnounce() {
      const status = localStorage.getItem("announcedClosed");
      if (status == true) return;
      localStorage.setItem("announcedClosed", true);
    }
  }
};
</script>

<style>
html body {
  background-color: #212226;
  color: #ccc;
}

#app {
  color: #ccc;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
}

#nav a.router-link-exact-active {
  color: #007bff;
  text-decoration: underline;
}

html,
body {
  height: 100%;
}

.anounce {
  background-color: rgb(190, 0, 0);
  color: #fff !important;
  margin: 10px;
  padding-bottom: 1px;
}

.anounce a {
  color: rgb(0, 255, 255) !important;
  /* text decoration */
  text-decoration: dotted underline;
}

#adsbox {
  display: none;
}

.maintenance {
  font-size: 1.25rem;
  font-weight: 300;
}

.rouded {
  border-radius: 10px !important;
}

/* TODO: FIX PAGE WRAP */
/* .page-wrap {
  min-height: 100%;
  margin-bottom: -142px; 
}
.page-wrap:after {
  content: "";
  display: block;
}
.site-footer, .page-wrap:after {
  height: 142px; 
} */
</style>
