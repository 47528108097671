<template>
  <div class="フッター">
    <b-container>
      <b-row>
        <b-col cols="6" class="text-left">
          <p>
            <router-link to="/privacy">Privacy</router-link>
            ● <router-link to="/tos">Terms of Service</router-link> ●
            <router-link to="/release">Releases</router-link>
            <br />
            <span>Code hosted on <a href="https://github.com/Yukiko-Dev-Team">Github</a>, Hosting and DNS <a href="https://asthriona.com">Asthriona ltd</a>.</span> <br />
            <span>&#169; Yukiko Dev Team 2019 - {{ years }} </span> 
            <span>●  Theme by <a href="https://twitter.com/KyokoDev">Takahashi Kyoko</a></span><br />
            Made with the ❤️ in Gifu, Japan ● Version: 
            <a
              href="https://sh.nishikino.xyz/YukikoChangeLogs"
              target="_blank"
              rel="noopener noreferrer"
              >2.2.0</a
            >
            <br>
            <span>Gateway Location: {{ location }}</span>
          </p>
        </b-col>
        <b-col cols="6" class="text-right">
          <a href="https://ko-fi.com/C0C61FCVH" target="_blank"
            ><img
              height="36"
              style="border: 0px; height: 36px"
              src="https://cdn.ko-fi.com/cdn/kofi3.png?v=3"
              border="0"
              alt="Buy Me a Coffee at ko-fi.com"
          /></a>
          <br />
          <a
            href="https://www.patreon.com/bePatron?u=14547677"
            data-patreon-widget-type="become-patron-button"
            ><img
              src="https://cdn.asthriona.com/i/2021/10/chrome_02102021-051546PM.png"
              height="35px"
              style="margin-top: 4px"
              alt=""
              srcset=""
          /></a>
        </b-col>
        <div class="foot_logo">
          <a
            href="https://asthriona.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <p>Operating company</p>
            <div class="figure">
              <img
                src="https://cdn.nishikino.xyz/asthriona/icons/footLogo.png"
                alt="logo"
                style="width: 100%"
              />
            </div>
          </a>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import axios from "axios"
export default {
  name: "TheFooter",
  data() {
    return {
      years: new Date().getFullYear(),
      location: "",
    };
  },
  mounted() {
    axios.get(`${process.env.VUE_APP_URI}/api/agi/location`).then((res) => {
      this.location = res.data.locaction
    })
    .catch(() => {
      this.location = "Unknown.";
    })
  }
};
</script>

<style scoped>
.フッター {
  color: #888;
  margin-top: 2rem;
  padding-top: 2rem;
  background-color: #232323;
}
a:link,
a:visited,
a:hover,
a:active {
  color: #d86100;
}
.フッター .foot_logo {
  display: table;
  max-width: 300px;
  width: 100%;
  margin: 0 auto 12px;
}
.フッター .foot_logo p {
  display: table-cell;
  width: 9em;
  vertical-align: middle;
  font-size: 12px;
  color: #ccc
}
.figure {
  display: table-cell;
  width: calc(100% - 7em);
}
.フッター .foot_logo .figure img {
  width: 160px;
}
</style>
