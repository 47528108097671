<template>
  <div class="header mt-4">
    <b-container>
      <div>
  <b-navbar toggleable="lg" type="dark" variant="#2c2d33">
    <b-navbar-brand to="/">{{ $t("header.home") }}</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <!-- <b-nav-item to="/dashboard">{{ $t("header.dashboard") }}</b-nav-item>
        <b-nav-item to="/status">{{ $t("header.status") }}</b-nav-item>
        <b-nav-item to="/news">News</b-nav-item> -->
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav 
      class="ml-auto lang"
      v-model="lang"
      @change="localSelect(event)"
      >
        <b-nav-item-dropdown :text="$t(`header.language`)" class="disabled" right disabled>
          <b-dropdown-item v-on:click="localSelect('en')" href="#">EN</b-dropdown-item>
          <b-dropdown-item v-on:click="localSelect('jp')" href="#">JP</b-dropdown-item>
          <b-dropdown-item v-on:click="localSelect('fi')" href="#">FI</b-dropdown-item>
          <b-dropdown-item v-on:click="localSelect('fr')" href="#">FR</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</div>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "TheHeader",
};
</script>

<style scoped>
.nav ul li {
  list-style-type: none;
  display: inline;
  text-align: center;
}
.nav {
  display: flex;
  justify-content: center;
}
.links {
   margin-left: .5rem;
   margin-right: .5rem;
}
.nolink a{
  color: white !important;
  cursor: text;
}
.router-link-exact-active {
  color: white !important;
  text-decoration-thickness: 2px;
}
.disabled {
  color: white !important;
  cursor: not-allowed;
}
</style>